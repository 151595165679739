<template>
  <div>
    <div class="row align-items-end">
      <div class="col-3">
        <label for="">Client </label>
        <select
          v-model="filter.customer_reference"
          class="form-select"
          required
        >
          <option selected value="">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="
              customer.blocked || customer.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for=""> Catégorie </label>
        <select v-model="filter.section_reference" class="form-select" required>
          <option selected value="">--</option>
          <option
            v-for="(section, index) in sections"
            :key="index++"
            :value="section.reference"
          >
            {{ section.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for=""> Article </label>
        <select v-model="filter.item_reference" class="form-select" required>
          <option selected value="">--</option>
          <option
            v-for="(item, index) in items.filter(
              (p) => p.section_reference == filter.section_reference
            )"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for=""> Stock </label>
        <select v-model="filter.stock_reference" class="form-select" required>
          <option selected value="">--</option>
          <option
            v-for="(stock, index) in stocks.filter(
              (p) => p.item_reference == filter.item_reference
            )"
            :key="index++"
            :value="stock.reference"
          >
            {{ stock.fullName }}
          </option>
        </select>
      </div>
    </div>
    <br />
    <div class="row align-items-end">
      <div class="col-3">
        <label for=""> Type de client </label>
        <select
          v-model="filter.customerType_reference"
          class="form-select"
          required
        >
          <option selected value="">--</option>
          <option
            v-for="(customerType, index) in customerTypes"
            :key="index++"
            :value="customerType.reference"
          >
            {{ customerType.fullName }}
          </option>
        </select>
      </div>
      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>
      <!-- <div class="col"></div> -->
      <div class="col-auto">
        <label for=""> </label>
        <button @click="search(filter)" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Actualiser
        </button>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="print(filter)" class="btn btn-secondary border">
          <i class="bi bi-printer fs-4"></i> Imprimer
        </button>
      </div>
    </div>

    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col" class="text-end">Quantité Achetée</th>
          <th scope="col" class="text-end">Quantité Vendue</th>
          <th scope="col" class="text-end">Stock Actuel</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in statisticsReport" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="item">
              {{ item.fullName }}
            </span>
          </td>

          <td class="text-end">
            <span class="text-success"> + {{ item.purchasedQuantity }} </span>
          </td>
          <td class="text-end">
            <span class="text-danger"> -{{ item.soldQuantity }} </span>
          </td>
          <td class="text-end">
            <span class="text-primary">
              {{ item.stockQuantity }}
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border-0">
          <td></td>

          <td>Total :</td>
          <td class=""></td>
          <td></td>
          <td></td>

          <td></td>
        </tr>
      </tfoot>
    </table>
    <br />
    <!-- {{ getLineChartDataReport }} -->
    <br />
    <!-- <div class="row">
      <div class="col">
        <line-chart
          :chart-data="getLineChartDataReport"
          :options="lineChartOptions"
        ></line-chart>
      </div>
    </div> -->
    <br />
    <div v-if="statisticsReport != ''" class="row justify-content-end">
      <div class="col-auto">
        <label for=""> </label>
        <button class="btn btn0 btn-secondary">
          <i class="bi bi-cloud-arrow-down"></i>
          Télécharger
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import LineChart from "../../components/chart/LineChart.vue";

export default {
  components: {
    LineChart,
  },

  data() {
    return {
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],

        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
    };
  },

  async beforeMount() {
    this.$store.dispatch("sale/fetchAllStatistics", this.filter);
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("item/getAll");
    this.$store.dispatch("section/getAll");
    this.$store.dispatch("stock/getAll");
    this.$store.dispatch("customer/getCustomerTypes");
  },
  computed: {
    ...mapGetters("customer", {
      customers: "getAll",
      customerTypes: "getCustomerTypes",
    }),
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),

    ...mapGetters("stock", {
      stocks: "getAll",
    }),
    ...mapGetters("sale", {
      statisticsReport: "getStatisticsReport",
    }),
  },
  methods: {
    async refresh() {
      this.$store.dispatch("sale/fetchAllStatistics", this.filter);
      this.$store.dispatch("customer/getAll");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("section/getAll");
      this.$store.dispatch("stock/getAll");
      this.$store.dispatch("customer/getCustomerTypes");
      this.filter = {};
    },
    async search(data) {
      await this.$store.dispatch("sale/fetchAllStatistics", data);
    },
  },
};
</script>
